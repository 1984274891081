<template>
<div class="d-flex text-center">
  <div class="w-100 fixed-top">
    <b-alert variant="danger" show v-if="errorMsg" @click="errorMsg = null" dismissible>
      {{ errorMsg }}
    </b-alert>
    <b-alert variant="warning" show v-if="warnMsg && config.showWarnings" @click="warnMsg = null" dismissible>
      {{ warnMsg }}
    </b-alert>    
    <div class="form-inline sub-mr-2 sub-mt-2" v-if="$debug.isOn">
      <button class="btn btn-primary btn-sm" @click="retry">Retry</button>
    </div>
    <log-output ref="logOutput" :show="$debug.isOn"/>
  </div>
  <div v-if="config.live" style="position: absolute; margin: 10px 0 0 20px; z-index: 10; font-size: 2em; opacity:50%">
    <button v-if="type == 'live'" class="btn btn-primary btn-sm mr-2" @click="goLive">Live</button>
    <button v-if="showChromecast" class="btn btn-primary btn-sm" @click="chromecast">Chromecast</button>
  </div>
  <video class="d-block mx-auto" width="100%" style="max-height: 100vh;" ref="video" controls></video>
</div>
</template>

<script>
import { getLog } from "@/services/log";
import Hls from 'hls.js';
import logOutput from '@/components/logOutput.vue';
import { getBrowser, fetchBlob, sleep, parseBool } from '@/services/utils';
let log = getLog("test-hls");

export default {
  components: { logOutput },
  props: {
    url: String,
    config: {
      type: Object,
      default: () => {return {}},
    }
  },
  data() {
    return {
      manifestUrl: null,
      type: 'live',
      showChromecast: false,
      totalTime: 0,

      errorMsg: null,
      warnMsg: null,
      fetchLoop: false,
    };
  },
  mounted() {
    this.$refs.logOutput.capture(log);
    this.type = this.$route.query.type;
    this.config.autoplay = this.config.autoplay || parseBool(this.$route.query.autoplay);
    this.config.startMuted = this.config.startMuted || parseBool(this.$route.query.startMuted);
    log.log("config", this.config);
    this.$refs.video.addEventListener("timeupdate", (e) => {
      // log.log("timeupdated", e.target.currentTime);
      this.$emit("update", {
        currentTime: e.target.currentTime,
        totalTime: this.totalTime,
      });
    });
    this.$refs.video.addEventListener("durationchange", (e) => {
      this.totalTime = e.target.duration;
    });
    this.$refs.video.addEventListener("ended", () => {
      // log.log("ended", e);
      this.$emit("ended");
    });
    if (this.$route.query.apple || ["safari", "mobilesafari"].includes(getBrowser())) {
      this.initAppleHLS();
    } else {
      this.initHLSjs();
      this.play();
    }
  },
  beforeDestroy() {
    this.fetchLoop = false;
    this.hls.destroy();
  },
  methods: {
    initAppleHLS() {
      let video = this.$refs.video;
      let canPayHLS = video.canPlayType('application/vnd.apple.mpegurl');
      if (!canPayHLS) {
        this.errorMsg = "Device does not support HLS, please try watching on another device";
        log.error("Apple device can't play HLS");
        return;
      }
      log.log("Using Apple HLS on Safari", canPayHLS ? "can play" : "cannot play" );
      video.addEventListener("error", (e) => {
        let error = e.target.error;
        log.log("HTMLMediaElement error code=", error.code, error);
      });
      video.src = this.url || this.$route.query.url;
      video.addEventListener('loadedmetadata', function() {
        video.play();
      });
    },
    initHLSjs() {
      let HLSisSupported = Hls.isSupported();
      log.log(`Hls.js ${Hls.version} isSupported=${HLSisSupported}`);
      if (!HLSisSupported) {
        this.errorMsg = "Device does not support HLS, please try watching on another device";
        log.error("HLSisSupported failed");
        return;
      }
      this.hls = new Hls({
        manifestLoadingMaxRetry: 10,
        manifestLoadingRetryDelay: 5000,
        debug:!!this.$route.query.debug,
      });
    },
    async play() {
      this.manifestUrl = this.url || this.$route.query.url;
      log.log("Play", this.manifestUrl);
      this.fetchLoop = true
      do {
        try {
          await fetchBlob(this.manifestUrl);
          this.fetchLoop = false;
        } catch (error) {
          log.log("fetchBlob error", error);
          await sleep(3000);
        }
      } while (this.fetchLoop);
      let video = this.$refs.video;
      if (!video) {
        log.error("cannot find video ref");
        return;
      }
      this.hls.attachMedia(video);
      this.hls.loadSource(this.manifestUrl);
      let that = this;
      this.hls.on(Hls.Events.MANIFEST_PARSED, function (levels, firstLevel) {
        log.log("Manifest Parsed"); levels, firstLevel;
        //log.log("Manifest", levels, firstLevel);
        if (that.config.autoplay) {
          video.play();
          video.muted = that.config.startMuted;
        }
      });
      this.hls.on(Hls.Events.FRAG_LOADED, function(event, data) {
        log.log("Frag Loaded", data.frag.relurl);
      });
      this.hls.on(Hls.Events.FRAG_CHANGED, function(event, data) {
        log.log("Frag Changed", data.frag.relurl);
      });
      this.hls.on(Hls.Events.ERROR, function (event, data) {
        log.log("Error", data);
        let msg = `${data.fatal?'Fatal ':''}Error: ${data.details} ${data.type}`;
        if (data.response)
          msg += `, response code: ${data.response.code}`;
        if (data.frag)
          msg += `, relurl: ${data.frag.relurl}`;
        if (data.error)
          msg += `, error: ${data.error}`;
        if (data.fatal)
          that.errorMsg = msg;
        else
          that.warnMsg = msg;
        /*
        // Trying to recover
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            log.log('fatal network error encountered, try to recover');
            this.hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            log.log('fatal media error encountered, try to recover');
            this.hls.recoverMediaError();
            break;
          default:
            // cannot recover
            this.hls.destroy();
            break;
        }
        */
      });
    },
    retry() {
      log.log("retry");
      this.hls.attachMedia(this.$refs.video);
      this.hls.loadSource(this.manifestUrl);
    },
    controlsPlay() {
      this.$refs.video.play();
    },
    controlsPause() {
      this.$refs.video.pause();
    },
    goLive() {
      log.log("goLive");
      let video = this.$refs.video;
      video.currentTime = this.hls.liveSyncPosition;
      if (video.paused)
        video.play();
    },
    chromecast() {

    }
  }
}
</script>

<style>

</style>